import { Key } from '@briebug/ngrx-auto-entity';

export class EquipmentUntrackedMachine {
  @Key id: number;
  name: string;
  delta: number;
  billingCode: string;
  billingType: string;
  equipClass: number;
}
