import { Key } from '@briebug/ngrx-auto-entity';
import { Job } from './job.model';
import {
  JobPlanCrewRequirementVM,
  JobPlanCrewRequirement,
} from './job-plan-crew-requirement.model';
import { JobPlanCrewAssignment } from './job-plan-crew-assignment.model';
import { format } from 'date-fns';
import { JobPlanEquipReqVM } from './job-plan-equip-req.model';
import { JobLocate } from './job-locate.model';
import {
  JobPlanTentative,
  JobPlanTentativeVM,
} from './job-plan-tentative.model';

export const jobPlanVMToJP = (jp: JobPlanVM): JobPlan => {
  return {
    ...jp,
    job: jp.job.id,
    start: format(jp.start, 'yyyy-MM-dd'),
    end: format(jp.end, 'yyyy-MM-dd'),
    completedDate: jp.completedDate
      ? format(jp.completedDate, 'yyyy-MM-dd')
      : null,
    datesStatus: jp.datesStatus.map(ds => ({
      ...ds,
      date: format(ds.date, 'yyyy-MM-dd'),
    })),
  };
};

export class JobPlan {
  @Key id?: string;
  job: string;
  trackEstimates: boolean;
  laborEstimate?: number;
  equipEstimate?: number;
  materialsEstimate?: number;
  waterPct: number;
  sewerPct: number;
  hourlyPct: number;
  locateRequired: number;
  start: string;
  end: string;
  completed?: boolean;
  completedDate?: string;
  details?: string;
  datesStatus?: { date: string; status: string }[];
  locateStatus?: string;
}

export interface JobPlanVM {
  id?: string;
  job: Job;
  trackEstimates: boolean;
  laborEstimate?: number;
  equipEstimate?: number;
  materialsEstimate?: number;
  waterPct: number;
  sewerPct: number;
  hourlyPct: number;
  locateRequired: number;
  start: Date;
  end: Date;
  completed?: boolean;
  completedDate?: Date;
  details?: string;
  datesStatus?: { date: Date; status: string }[];
  tentative: JobPlanTentativeVM[];
  locateStatus?: string;
}

export interface JobPlanDetailsVM {
  crewRequirements: JobPlanCrewRequirementVM[];
  equipRequirements: JobPlanEquipReqVM[];
  locates: JobLocate[];
}

export interface CreateCrewReqPayload {
  crewReq: JobPlanCrewRequirement;
  crewAssignments: JobPlanCrewAssignment[];
}
