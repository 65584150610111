import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Equipment } from '@app/models/equipment.model';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { EquipmentLocations } from '@app/models/equipment-locations.model';

@Injectable({ providedIn: 'root' })
export class EquipService {
  constructor(private http: HttpClient, private api: ApiService) {}

  loadAll(): Observable<Equipment> {
    return this.http.get<Equipment>(`${this.api.url}/equipment`);
  }

  loadLocations(): Observable<EquipmentLocations> {
    return this.http.get<EquipmentLocations>(
      `${this.api.url}/equipment/machines/locations`
    );
  }
}
