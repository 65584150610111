import { Key } from '@briebug/ngrx-auto-entity';
import {
  JobPlanCrewAssignment,
  JobPlanCrewAssignmentVM,
} from './job-plan-crew-assignment.model';

export class JobPlanCrewRequirement {
  @Key id?: string;
  name: string;
  jobPlan: string;
  flexCrew: boolean;
  foreman: boolean;
  nonForeman: number;
  dates: string[];
  crew?: string;
}

export interface CrewReqDate {
  date: Date;
  crewAssignment: JobPlanCrewAssignmentVM;
}

export class JobPlanCrewRequirementVM {
  id?: string;
  name: string;
  jobPlan: string;
  flexCrew: boolean;
  foreman: boolean;
  nonForeman: number;
  dates: CrewReqDate[];
  crewType: string;
}
