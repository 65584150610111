import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  loadUserMeSuccess,
  loadUserMeError,
  loadUserMe,
} from './user-me.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { UserMeService } from '@services/api/user-me.service';
import { of } from 'rxjs';

@Injectable()
export class UserMeEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserMe),
      mergeMap(() =>
        this.userMeService.load().pipe(
          map(res => loadUserMeSuccess({ payload: res })),
          catchError(err => of(loadUserMeError(err)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userMeService: UserMeService
  ) {}
}
