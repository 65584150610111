import { createEffect, ofType, Actions } from '@ngrx/effects';
import { LoadAllSuccess, LoadManySuccess } from '@briebug/ngrx-auto-entity';
import {
  loadJobPlanner,
  loadJobPlannerSuccess,
  loadJobPlannerError,
} from './job-planner.actions';
import { exhaustMap, catchError, map } from 'rxjs/operators';
import { JobPlannerService } from '@services/api/job-planner.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import {
  JobPlan,
  Job,
  JobPlanManaged,
  JobPlanManager,
  JobPlanTentative,
} from '@app/models';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class JobPlannerEffects {
  loadJobPlanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadJobPlanner),
      exhaustMap(action =>
        this.jobPlannerService.load(action.dateRange).pipe(
          map(jobPlanner => {
            this.store$.dispatch(
              new LoadAllSuccess(
                JobPlan,
                jobPlanner.jobPlans.map(jp => ({ ...jp, details: 'none' }))
              )
            );
            this.store$.dispatch(new LoadManySuccess(Job, jobPlanner.jobs));
            this.store$.dispatch(
              new LoadAllSuccess(JobPlanManaged, jobPlanner.jobPlanManaged)
            );
            this.store$.dispatch(
              new LoadAllSuccess(JobPlanManager, jobPlanner.managers)
            );
            this.store$.dispatch(
              new LoadManySuccess(JobPlanTentative, jobPlanner.tentative)
            );
            return loadJobPlannerSuccess({ dateRange: action.dateRange });
          }),
          catchError(error => {
            return of(loadJobPlannerError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private jobPlannerService: JobPlannerService,
    private store$: Store<AppState>
  ) {}
}
