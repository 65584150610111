import { createAction, props, Store } from '@ngrx/store';
import { TruckingMove, TruckingEquipLocation } from '@app/models';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { AppState } from './app.state';
import {
  TruckingMoveService,
  UnloadMachinesPayload,
  RerouteMachinePayload,
} from '@services/api/trucking-move.service';
import {
  DeleteManySuccess,
  UpdateSuccess,
  LoadAll,
  ofEntityType,
  EntityActionTypes,
} from '@briebug/ngrx-auto-entity';
import {
  loadAllTrucking,
  loadTruckingEquipLocs,
} from './trucking/trucking.actions';

export const unloadMachines = createAction(
  '[Trucking Move] Unload Machines',
  props<{ payload: UnloadMachinesPayload }>()
);

export const rerouteMachine = createAction(
  '[Trucking Move] Reroute Machine',
  props<{ payload: RerouteMachinePayload }>()
);

@Injectable()
export class TruckingMoveEffects {
  changeLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(
        TruckingMove,
        EntityActionTypes.CreateSuccess,
        EntityActionTypes.DeleteSuccess,
        EntityActionTypes.DeleteManySuccess,
        EntityActionTypes.UpdateSuccess
      ),
      map(() => loadTruckingEquipLocs())
    )
  );

  unloadMachines$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unloadMachines),
        exhaustMap(action =>
          this.truckingMoveService.unloadMachines(action.payload).pipe(
            map(moves => {
              this.store$.dispatch(new DeleteManySuccess(TruckingMove, moves));
              this.store$.dispatch(loadAllTrucking());
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  rerouteMachine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rerouteMachine),
        exhaustMap(action =>
          this.truckingMoveService.rerouteMachine(action.payload).pipe(
            map(move => {
              this.store$.dispatch(new UpdateSuccess(TruckingMove, move));
              this.store$.dispatch(loadAllTrucking());
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private truckingMoveService: TruckingMoveService,
    private store$: Store<AppState>
  ) {}
}
