import { createReducer, Action, on, createFeatureSelector } from '@ngrx/store';
import * as actions from './trucking.actions';
import { EquipTypeFilter } from '../equipment/equipment.reducer';
import { AppState } from '../app.state';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
  typeFilter: EquipTypeFilter;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
  typeFilter: {
    equipType: null,
    optionTypes: [],
  },
};

const truckingReducer = createReducer(
  initialState,
  on(actions.loadAllTrucking, state => ({
    ...state,
    typeFilter: { ...state.typeFilter },
    loading: true,
    loaded: false,
    error: null,
  })),
  on(actions.loadAllTruckingSuccess, state => ({
    ...state,
    typeFilter: { ...state.typeFilter },
    loading: false,
    loaded: true,
    error: null,
  })),
  on(actions.setTruckingEquipTypeFilter, (state, newFilter) => ({
    ...state,
    typeFilter: { ...newFilter },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return truckingReducer(state, action);
}

export const selectTrucking = createFeatureSelector<AppState, State>(
  'trucking'
);
