import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, createAction, props } from '@ngrx/store';
import { AppState } from './app.state';
import {
  JobPlanService,
  CreateJobPlanPayload,
} from '@services/api/job-plan.service';
import { map, exhaustMap } from 'rxjs/operators';
import {
  CreateSuccess,
  LoadMany,
  Update,
  UpdateSuccess,
  LoadManySuccess,
  LoadSuccess,
  DeleteSuccess,
  CreateManySuccess,
} from '@briebug/ngrx-auto-entity';
import {
  JobPlan,
  JobPlanManager,
  Job,
  JobPlanManaged,
  JobPlanVM,
  JobPlanCrewRequirement,
  JobPlanCrewAssignment,
  Crew,
  CreateCrewReqPayload,
  JobPlanEquipReq,
  JobLocate,
} from '@app/models';
import { format } from 'date-fns';
import { createJobPlanCrewAssignments } from './job-plan-crew-assignment.effects';

export const createJobPlan = createAction(
  '[Job Plan] Create Job Plan',
  props<{ payload: CreateJobPlanPayload }>()
);

export const updateJobPlan = createAction(
  '[Job Plan] Update Job Plan',
  props<{ payload: JobPlan }>()
);

export const getJobPlanDetails = createAction(
  '[Job Plan] Get Job Plan Details',
  props<{ payload: JobPlan }>()
);

export const getJobPlanDetailsSuccess = createAction(
  '[Job Plan] Get Job Plan Details',
  props<{ payload: JobPlan }>()
);

export const createJobPlanCrewReq = createAction(
  '[Job Plan] Create Crew Requirement',
  props<{ payload: CreateCrewReqPayload }>()
);

export const updateJobPlanCrewReq = createAction(
  '[Job Plan] Update Crew Requirement',
  props<{ payload: JobPlanCrewRequirement }>()
);

export const deleteJobPlanCrewReq = createAction(
  '[Job Plan] Delete Crew Requirement',
  props<{ payload: JobPlanCrewRequirement }>()
);

export const createJobPlanEquipReqs = createAction(
  '[Job Plan] Create Equipment Requirement',
  props<{ payload: JobPlanEquipReq[] }>()
);

export const updateJobPlanEquipReq = createAction(
  '[Job Plan] Update Equipment Requirement',
  props<{ payload: JobPlanEquipReq }>()
);

export const deleteJobPlanEquipReq = createAction(
  '[Job Plan] Delete Equipment Requirement',
  props<{ payload: JobPlanEquipReq }>()
);

@Injectable()
export class JobPlanEffects {
  createJobPlanCrewReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobPlanCrewReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.createCrewReq(payload.crewReq).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(JobPlanCrewRequirement, response.crewReq)
              );
              this.store$.dispatch(
                new LoadSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
              const crewReqId = response.crewReq.id;
              const crewAssignments = payload.crewAssignments.map(ca => ({
                ...ca,
                jpCrewReq: crewReqId,
              }));
              if (crewAssignments.length > 0) {
                this.store$.dispatch(
                  createJobPlanCrewAssignments({ payload: crewAssignments })
                );
              }
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  createJobPlanEquipReqs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobPlanEquipReqs),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.createManyEquipReqs(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateManySuccess(
                  JobPlanEquipReq,
                  response.equipmentRequests
                )
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateJobPlanCrewReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateJobPlanCrewReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.updateCrewReq(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlanCrewRequirement, response.crewReq)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateJobPlanEquipReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateJobPlanEquipReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.updateEquipReq(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlanEquipReq, response.equipmentRequest)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  deleteJobPlanCrewReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteJobPlanCrewReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.deleteCrewReq(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new DeleteSuccess(JobPlanCrewRequirement, payload)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  deleteJobPlanEquipReq$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteJobPlanEquipReq),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.deleteEquipReq(payload).pipe(
            map(response => {
              this.store$.dispatch(new DeleteSuccess(JobPlanEquipReq, payload));
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  createJobPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobPlan),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.createWithManager(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(JobPlan, response.jobPlan)
              );
              this.store$.dispatch(new LoadSuccess(Job, response.job));
              this.store$.dispatch(
                new CreateSuccess(JobPlanManaged, response.jobPlanManaged)
              );
              if ('manager' in response) {
                this.store$.dispatch(
                  new LoadSuccess(JobPlanManager, response.manager)
                );
              }
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateJobPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateJobPlan),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.replace(null, payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, {
                  ...response,
                  details: payload.details,
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  getJobPlanDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getJobPlanDetails),
        map(action => action.payload),
        exhaustMap(payload => {
          const completedDate =
            payload.completedDate === null
              ? null
              : typeof payload.completedDate === 'string'
              ? payload.completedDate
              : format(payload.completedDate, 'yyyy-MM-dd');
          console.log(typeof payload);
          const jp: JobPlan = {
            ...payload,
            details: 'loading',
          };
          this.store$.dispatch(new UpdateSuccess(JobPlan, jp));
          return this.jobPlanService.getDetails(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new LoadManySuccess(
                  JobPlanCrewRequirement,
                  response.crewRequirements
                )
              );
              this.store$.dispatch(
                new LoadManySuccess(
                  JobPlanCrewAssignment,
                  response.crewAssignments
                )
              );
              this.store$.dispatch(new LoadManySuccess(Crew, response.crews));
              this.store$.dispatch(
                new LoadManySuccess(
                  JobPlanEquipReq,
                  response.equipmentRequirements
                )
              );
              this.store$.dispatch(
                new LoadManySuccess(JobLocate, response.locates)
              );
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, { ...jp, details: 'loaded' })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobPlanService: JobPlanService,
    private store$: Store<AppState>
  ) {}
}
