import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { DateFnsModule } from 'ngx-date-fns';

import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { StateModule } from './state/state.module';
import { EquipmentClass } from './models/equipment-class.model';
import { MappedEntityService } from '@services/api/mapped-entity.service';
import { ApiInterceptorService } from '@services/api/api-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  EquipmentType,
  EquipmentModel,
  EquipmentMachine,
  EquipmentOption,
  EquipmentOptionType,
  EquipmentUntrackedClass,
  EquipmentUntrackedMachine,
  User,
  UserRole,
  ImportStatus,
  Vendor,
  MaterialCategory,
  MaterialVendor,
  SubcontractorCategory,
  SubcontractorVendor,
  RestorationCategory,
  RestorationVendor,
  RestorationType,
  JobEquipment,
  Job,
  TruckingRequirement,
  TruckingMove,
  TruckingEquipLocation,
  JobPlan,
  JobPlanManager,
  JobPlanCrewRequirement,
  Crew,
  JobPlanCrewAssignment,
  JobLocate,
  JobPlanTentative,
} from './models';
import { TruckingMoveService } from '@services/api/trucking-move.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JobPlanService } from '@services/api/job-plan.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({ mode: 'md' }),
    IonicStorageModule.forRoot(),
    DateFnsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    StateModule,
    PerfectScrollbarModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: Crew, useClass: MappedEntityService },
    { provide: EquipmentClass, useClass: MappedEntityService },
    { provide: EquipmentType, useClass: MappedEntityService },
    { provide: EquipmentModel, useClass: MappedEntityService },
    { provide: EquipmentMachine, useClass: MappedEntityService },
    { provide: EquipmentOption, useClass: MappedEntityService },
    { provide: EquipmentOptionType, useClass: MappedEntityService },
    { provide: EquipmentUntrackedClass, useClass: MappedEntityService },
    { provide: EquipmentUntrackedMachine, useClass: MappedEntityService },
    { provide: Job, useClass: MappedEntityService },
    { provide: JobEquipment, useClass: MappedEntityService },
    { provide: JobPlan, useClass: MappedEntityService },
    { provide: JobPlanCrewRequirement, useClass: MappedEntityService },
    { provide: JobPlanCrewAssignment, useClass: MappedEntityService },
    { provide: JobPlanManager, useClass: MappedEntityService },
    { provide: MaterialCategory, useClass: MappedEntityService },
    { provide: MaterialVendor, useClass: MappedEntityService },
    { provide: RestorationCategory, useClass: MappedEntityService },
    { provide: RestorationType, useClass: MappedEntityService },
    { provide: RestorationVendor, useClass: MappedEntityService },
    { provide: SubcontractorCategory, useClass: MappedEntityService },
    { provide: SubcontractorVendor, useClass: MappedEntityService },
    { provide: User, useClass: MappedEntityService },
    { provide: UserRole, useClass: MappedEntityService },
    { provide: ImportStatus, useClass: MappedEntityService },
    { provide: Vendor, useClass: MappedEntityService },
    { provide: TruckingRequirement, useClass: MappedEntityService },
    { provide: TruckingMove, useClass: TruckingMoveService },
    { provide: TruckingEquipLocation, useClass: MappedEntityService },
    { provide: JobLocate, useClass: MappedEntityService },
    { provide: JobPlanTentative, useClass: MappedEntityService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
