import { Key } from '@briebug/ngrx-auto-entity';
import { EquipmentType } from './equipment-type.model';
import { Job } from './job.model';
import { EquipmentOptionType } from './equipment-option-type.model';

export class TruckingRequirement {
  @Key id?: string;
  equipmentType: number;
  notes: string;
  job: string;
  requiredBy: string;
  options: any[];
}

export interface TruckingRequirementDetails {
  id?: string;
  equipmentType: EquipmentType;
  notes: string;
  job: Job;
  requiredBy: Date;
  date: string;
  options: EquipmentOptionType[];
}
