import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { UserMe } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class UserMeService {
  constructor(private http: HttpClient, private api: ApiService) {}

  load(): Observable<UserMe> {
    return this.http.get<UserMe>(`${this.api.url}/users/me`);
  }
}
