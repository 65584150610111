import { Key } from '@briebug/ngrx-auto-entity';

export class UserTimecard {
  @Key id?: string;
  user: string;
  timecardType: string;
  clockIn: string;
  clockOut?: string;
  breakMinutes?: number;
  clockInActual: string;
  clockOutActual?: string;
  clockInLat: number;
  clockInLng: number;
  clockOutLat?: number;
  clockOutLng?: number;
}
