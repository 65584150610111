import { Key } from '@briebug/ngrx-auto-entity';
import { User } from './user.model';
import { EquipmentMachineFlat } from './equipment-machine.model';

export interface TruckingMoveLocation {
  address: string;
  city: string;
  state: string;
  jobNumber: string;
  jobId: string;
  name: string;
  lat: number;
  lng: number;
  tmpDrop: boolean;
}

export class TruckingMove {
  @Key id?: string;
  equip: number;
  equipReq?: string;
  job?: string;
  tempDrop?: boolean;
  requiredBy?: string;
  status?: string;
  pickup?: string;
  dropoff?: string;
  origin?: TruckingMoveLocation;
  destination?: TruckingMoveLocation;
  employee?: string;
  moveType?: string;
}

export class TruckingMoveDetails {
  id?: string;
  equip: EquipmentMachineFlat;
  equipReq?: string;
  requiredBy?: Date;
  status?: string;
  pickup?: Date;
  dropoff?: Date;
  origin?: TruckingMoveLocation;
  destination?: TruckingMoveLocation;
  employee?: User;
  moveType?: string;
}
