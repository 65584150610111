import { Key } from '@briebug/ngrx-auto-entity';

export class Job {
  @Key id: string;
  jobNumber: string;
  name: string;
  customer: string;
  address: string;
  city: string;
  state: string;
  jobType: string;
  active: boolean;
  contact: string;
  geocoordStatus: string;
  lat: number;
  lng: number;
  accuracy: string;
  accuracyType: string;
  unassigned: boolean;
}
