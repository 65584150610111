import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanCrewRequirement } from '@app/models';

export const {
  initialState: jobPlanCrewReqInitialState,
  selectors: jobPlanCrewReqSelectors,
  facade: JobPlanCrewReqFacadeBase,
} = buildState(JobPlanCrewRequirement);

export function jobPlanCrewReqReducer(
  state = jobPlanCrewReqInitialState
): IEntityState<JobPlanCrewRequirement> {
  return state;
}
