import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import {
  JobPlanManager,
  JobPlan,
  JobPlanManaged,
  Job,
  JobPlanTentative,
} from '@app/models';
import { Observable } from 'rxjs';

export interface JobPlannerDateParams {
  minDate: string;
  maxDate: string;
}

export interface JobPlannerResponse {
  managers: JobPlanManager[];
  jobPlans: JobPlan[];
  jobPlanManaged: JobPlanManaged[];
  jobs: Job[];
  tentative: JobPlanTentative[];
}

@Injectable({
  providedIn: 'root',
})
export class JobPlannerService {
  constructor(private http: HttpClient, private api: ApiService) {}

  load(dateParams: JobPlannerDateParams): Observable<JobPlannerResponse> {
    return this.http.get<JobPlannerResponse>(`${this.api.url}/job-plans`, {
      params: { min_date: dateParams.minDate, max_date: dateParams.maxDate },
    });
  }
}
