import { Injectable } from '@angular/core';
import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import {
  JobPlan,
  JobPlanManager,
  Job,
  JobPlanManaged,
  JobPlanCrewRequirement,
  JobPlanVM,
  JobPlanCrewAssignment,
  Crew,
  JobPlanEquipReq,
  JobLocate,
} from '@app/models';
import { Observable } from 'rxjs';

export interface CreateJobPlanPayload {
  manager: string;
  jobPlan: JobPlan;
}

export interface CreateJobPlanResponse {
  jobPlan: JobPlan;
  jobPlanManaged: JobPlanManaged;
  job: Job;
  manager?: JobPlanManager;
}

export interface CreateCrewReqResponse {
  crewReq: JobPlanCrewRequirement;
  jobPlan: JobPlan;
}

export interface UpdateCrewReqResponse {
  crewReq: JobPlanCrewRequirement;
  jobPlan: JobPlan;
}

export interface JobPlanDetailsResponse {
  crewRequirements: JobPlanCrewRequirement[];
  crewAssignments: JobPlanCrewAssignment[];
  crews: Crew[];
  equipmentRequirements: JobPlanEquipReq[];
  locates: JobLocate[];
}

export interface CreateManyCrewAssignmentsResponse {
  crewAssignments: JobPlanCrewAssignment[];
  crews: Crew[];
  jobPlan: JobPlan;
}

export interface CreateCrewAssignmentResponse {
  crewAssignment: JobPlanCrewAssignment;
  crew: Crew;
  jobPlan: JobPlan;
}

export interface DeleteCrewReqResponse {
  jobPlan: JobPlan;
}

export interface CreateManyEquipReqsResponse {
  equipmentRequests: JobPlanEquipReq[];
  jobPlan: JobPlan;
}

export interface UpdateEquipReqResponse {
  equipmentRequest: JobPlanEquipReq;
  jobPlan: JobPlan;
}

export interface DeleteEquipReqResponse {
  jobPlan: JobPlan;
}

@Injectable({
  providedIn: 'root',
})
export class JobPlanService implements IAutoEntityService<any> {
  constructor(private http: HttpClient, private api: ApiService) {}

  createWithManager(
    payload: CreateJobPlanPayload
  ): Observable<CreateJobPlanResponse> {
    return this.http.post<CreateJobPlanResponse>(
      `${this.api.url}/job-plans`,
      payload
    );
  }

  update(entityInfo: IEntityInfo, entity: JobPlan): Observable<JobPlan> {
    return this.http.patch<JobPlan>(
      `${this.api.url}/job-plans/${entity.id}`,
      entity
    );
  }

  replace(entityInfo: IEntityInfo, entity: JobPlan): Observable<JobPlan> {
    return this.http.put<JobPlan>(
      `${this.api.url}/job-plans/${entity.id}`,
      entity
    );
  }

  getDetails(entity: JobPlan | JobPlanVM): Observable<JobPlanDetailsResponse> {
    return this.http.get<JobPlanDetailsResponse>(
      `${this.api.url}/job-plans/${entity.id}`
    );
  }

  createCrewReq(
    entity: JobPlanCrewRequirement
  ): Observable<CreateCrewReqResponse> {
    return this.http.post<CreateCrewReqResponse>(
      `${this.api.url}/job-plans/crew-requirements`,
      entity
    );
  }

  updateCrewReq(
    entity: JobPlanCrewRequirement
  ): Observable<UpdateCrewReqResponse> {
    return this.http.put<UpdateCrewReqResponse>(
      `${this.api.url}/job-plans/crew-requirements/${entity.id}`,
      entity
    );
  }

  deleteCrewReq(
    entity: JobPlanCrewRequirement
  ): Observable<DeleteCrewReqResponse> {
    return this.http.delete<DeleteCrewReqResponse>(
      `${this.api.url}/job-plans/crew-requirements/${entity.id}`
    );
  }

  createCrewAssignment(
    entity: JobPlanCrewAssignment
  ): Observable<CreateCrewAssignmentResponse> {
    return this.http.post<CreateCrewAssignmentResponse>(
      `${this.api.url}/job-plans/crew-assignments`,
      entity
    );
  }

  createManyCrewAssignments(
    entity: JobPlanCrewAssignment[]
  ): Observable<CreateManyCrewAssignmentsResponse> {
    return this.http.post<CreateManyCrewAssignmentsResponse>(
      `${this.api.url}/job-plans/crew-assignments`,
      entity
    );
  }

  deleteCrewAssignment(entity: JobPlanCrewAssignment): Observable<JobPlan> {
    return this.http.delete<JobPlan>(
      `${this.api.url}/job-plans/crew-assignments/${entity.id}`
    );
  }

  createManyEquipReqs(
    entities: JobPlanEquipReq[]
  ): Observable<CreateManyEquipReqsResponse> {
    return this.http.post<CreateManyEquipReqsResponse>(
      `${this.api.url}/job-plans/equipment-requirements`,
      entities
    );
  }

  updateEquipReq(entity: JobPlanEquipReq): Observable<UpdateEquipReqResponse> {
    return this.http.put<UpdateEquipReqResponse>(
      `${this.api.url}/job-plans/equipment-requirements/${entity.id}`,
      entity
    );
  }

  deleteEquipReq(entity: JobPlanEquipReq): Observable<DeleteEquipReqResponse> {
    return this.http.delete<DeleteEquipReqResponse>(
      `${this.api.url}/job-plans/equipment-requirements/${entity.id}`
    );
  }
}
