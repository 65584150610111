import { Key } from '@briebug/ngrx-auto-entity';

export const HourlyCalcMap = {
  ADD: 'Add to parent hours',
  REP: 'Replace parent hours',
};

export class EquipmentOption {
  @Key id: number;
  equipment: number;
  optionType: number;
  billable: boolean;
  oneTime: boolean;
  oneTimeCode: string;
  hourly: boolean;
  hourlyCode: string;
  hourlyCalc: string;
}
