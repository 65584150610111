import { Key } from '@briebug/ngrx-auto-entity';
import { User } from './user.model';

export class JobPlanManager {
  id: string;
  @Key manager: string;
  user: string;
  delta: number;
  open: boolean;
}

export interface JobPlanManagerVM {
  id: string;
  manager: User;
  user: User;
  delta: number;
  open: boolean;
}
