import { Key } from '@briebug/ngrx-auto-entity';

export class User {
  @Key id: string;
  username: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  suffix: string;
  mobile: string;
  accountingId: string;
  primaryRole: number;
  active: boolean;
  firstLogin: boolean;
}

export interface UserVM {
  id: string;
  username: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  suffix: string;
  mobile: string;
  accountingId: string;
  primaryRole: string;
  active: boolean;
  firstLogin: boolean;
}
