import { createReducer, Action, on, createFeatureSelector } from '@ngrx/store';
import * as actions from './equipment.actions';
import { AppState } from '../app.state';

export interface EquipTypeFilter {
  equipType: number;
  optionTypes: number[];
}

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
  typeFilter: EquipTypeFilter;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
  typeFilter: {
    equipType: null,
    optionTypes: [],
  },
};

const equipmentReducer = createReducer(
  initialState,
  on(actions.loadAllEquipment, actions.loadEquipmentLocations, state => ({
    ...state,
    typeFilter: { ...state.typeFilter },
    loading: true,
    loaded: false,
    error: null,
  })),
  on(
    actions.loadAllEquipmentSuccess,
    actions.loadEquipmentLocationsSuccess,
    state => ({
      ...state,
      typeFilter: { ...state.typeFilter },
      loading: false,
      loaded: true,
      error: null,
    })
  ),
  on(actions.setEquipmentTypeFilter, (state, newFilter) => ({
    ...state,
    typeFilter: { ...newFilter },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return equipmentReducer(state, action);
}

export const selectEquipment = createFeatureSelector<AppState, State>(
  'equipment'
);
