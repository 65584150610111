import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Restoration } from '@app/models';

@Injectable({ providedIn: 'root' })
export class RestorationService {
  constructor(private http: HttpClient, private api: ApiService) {}

  loadAll(): Observable<Restoration> {
    return this.http.get<Restoration>(`${this.api.url}/restoration`);
  }
}
