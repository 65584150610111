import { RestorationCategory } from './restoration-category.model';
import { RestorationVendor } from './restoration-vendor.model';
import { RestorationType } from './restoration-type.model';
import { Vendor } from './vendor.model';

export class Restoration {
  restorationCategories: RestorationCategory[];
  restorationVendors: RestorationVendor[];
  restorationTypes: RestorationType[];
  vendors: Vendor[];
}
