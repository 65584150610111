import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Job } from '@app/models';

export const {
  initialState: jobInitialState,
  selectors: jobSelectors,
  facade: JobFacadeBase,
} = buildState(Job);

export function jobReducer(state = jobInitialState): IEntityState<Job> {
  return state;
}
