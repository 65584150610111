import { createReducer, Action, on } from '@ngrx/store';
import * as actions from './job-planner.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
  startDate: string;
  endDate: string;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
  startDate: null,
  endDate: null,
};

const jobPlannerReducer = createReducer(
  initialState,
  on(actions.loadJobPlanner, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    startDate: null,
    endDate: null,
  })),
  on(actions.loadJobPlannerSuccess, (state, { dateRange }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    startDate: dateRange.minDate,
    endDate: dateRange.maxDate,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return jobPlannerReducer(state, action);
}
