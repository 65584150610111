import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanEquipReq } from '@app/models';

export const {
  initialState: jobPlanEquipReqInitialState,
  selectors: jobPlanEquipReqSelectors,
  facade: JobPlanEquipReqFacadeBase,
} = buildState(JobPlanEquipReq);

export function jobPlanEquipReqReducer(
  state = jobPlanEquipReqInitialState
): IEntityState<JobPlanEquipReq> {
  return state;
}
