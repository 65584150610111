import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { appMetaReducers, appReducer } from './app.state';
import { NgrxAutoEntityModule } from '@briebug/ngrx-auto-entity';
import { environment } from '@env/environment';
import { EquipmentEffects } from './equipment/equipment.effects';
import { MaterialsEffects } from './materials/materials.effects';
import { SubcontractorsEffects } from './subcontractors/subcontractors.effects';
import { RestorationEffects } from './restoration/restoration.effects';
import { TruckingEffects } from './trucking/trucking.effects';
import { TruckingMoveEffects } from './trucking-move.effects';
import { UserMeEffects } from './user-me/user-me.effects';
import { JobPlannerEffects } from './job-planner/job-planner.effects';
import { JobPlanEffects } from './job-plan.effects';
import { JobPlanCrewAssignmentEffects } from './job-plan-crew-assignment.effects';

@NgModule({
  imports: [
    StoreModule.forRoot(appReducer, { metaReducers: appMetaReducers }),
    EffectsModule.forRoot([
      EquipmentEffects,
      MaterialsEffects,
      SubcontractorsEffects,
      RestorationEffects,
      JobPlannerEffects,
      JobPlanEffects,
      JobPlanCrewAssignmentEffects,
      TruckingEffects,
      TruckingMoveEffects,
      UserMeEffects,
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    NgrxAutoEntityModule.forRoot(),
  ],
})
export class StateModule {}
