import { Key } from '@briebug/ngrx-auto-entity';

export class JobEquipment {
  @Key id?: string;
  job?: string;
  tmpDrop?: boolean;
  tmpLat?: number;
  tmpLng?: number;
  tmpAddress?: string;
  tmpCity?: string;
  tmpState?: string;
  equip: number;
  equipRequirement?: string;
  status: string;
  inTrucking?: string;
  outTrucking?: string;
}
