import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { ApiService } from './api.service';

const urlMap = {
  Crew: 'crews',
  EquipmentClass: 'equipment/classes',
  EquipmentType: 'equipment/types',
  EquipmentModel: 'equipment/models',
  EquipmentMachine: 'equipment/machines',
  EquipmentOption: 'equipment/machine-options',
  EquipmentOptionType: 'equipment/option-types',
  EquipmentUntrackedClass: 'equipment/untracked-classes',
  EquipmentUntrackedMachine: 'equipment/untracked',
  User: 'users',
  UserRole: 'user-roles',
  ImportStatus: 'import-status',
  Job: 'jobs',
  JobEquipment: 'job-equipment',
  JobLocate: 'job-locates',
  JobPlan: 'job-plans',
  JobPlanCrewRequirement: 'job-plans/crew-requirements',
  JobPlanManager: 'job-plans/managers',
  JobPlanCrewAssignment: 'job-plans/crew-assignments',
  JobPlanTentative: 'job-plans/tentative',
  MaterialVendor: 'materials/vendors',
  MaterialCategory: 'materials/categories',
  RestorationCategory: 'restoration/categories',
  RestorationType: 'restoration/types',
  RestorationVendor: 'restoration/vendors',
  SubcontractorVendor: 'subcontractors/vendors',
  SubcontractorCategory: 'subcontractors/categories',
  TruckingMove: 'trucking/moves',
  TruckingRequirement: 'trucking/requirements',
  Vendor: 'vendors',
};

@Injectable()
export class MappedEntityService implements IAutoEntityService<any> {
  constructor(private http: HttpClient, private api: ApiService) {}

  load(entityInfo: IEntityInfo, id: any): Observable<any> {
    return this.http.get<any>(
      `${this.api.url}/${urlMap[entityInfo.modelName]}/${id}`
    );
  }

  loadAll(entityInfo: IEntityInfo): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.api.url}/${urlMap[entityInfo.modelName]}`
    );
  }

  create(entityInfo: IEntityInfo, entity: any): Observable<any> {
    return this.http.post<any>(
      `${this.api.url}/${urlMap[entityInfo.modelName]}`,
      entity
    );
  }

  update(entityInfo: IEntityInfo, entity: any): Observable<any> {
    return this.http.patch<any>(
      `${this.api.url}/${urlMap[entityInfo.modelName]}/${entity.id}`,
      entity
    );
  }

  updateMany(entityInfo: IEntityInfo, entities: any[]): Observable<any> {
    return this.http.put<any>(
      `${this.api.url}/${urlMap[entityInfo.modelName]}`,
      entities
    );
  }

  replace(entityInfo: IEntityInfo, entity: any): Observable<any> {
    return this.http.put<any>(
      `${this.api.url}/${urlMap[entityInfo.modelName]}/${entity.id}`,
      entity
    );
  }

  delete(entityInfo: IEntityInfo, entity: any): Observable<any> {
    return this.http
      .delete<any>(
        `${this.api.url}/${urlMap[entityInfo.modelName]}/${entity.id}`
      )
      .pipe(map(() => entity));
  }
}
