import { Key } from '@briebug/ngrx-auto-entity';

export class ImportStatus {
  @Key id: string;
  entity: string;
  status: string;
  user: string;
  recordsBefore: number;
  recordsAfter: number;
  started: string;
  completed: string;
}
