import { Key } from '@briebug/ngrx-auto-entity';
import { EquipmentModel } from './equipment-model.model';
import { EquipmentType } from './equipment-type.model';
import { EquipmentOption } from './equipment-option.model';
import { Job } from './job.model';
import { JobEquipment } from './job-equipment.model';

export const EquipMachineBillingMap = {
  HR: 'Hourly',
  DY: 'Daily',
};

export interface EquipmentRentalDetails {
  id?: number;
  billingCode: string;
  equipMake: string;
  equipModel: string;
  rentedDate: string;
  returnedDate?: string;
}

export class EquipmentMachine {
  @Key id?: number;
  number: string;
  billingType: string;
  transportType: string;
  active: boolean;
  qrCode?: string;
  makeModel?: number;
  rental: boolean;
  rentalType?: number;
  rentalDetails?: EquipmentRentalDetails;
}

export interface EquipmentMachineFlat {
  machine: EquipmentMachine;
  rental: boolean;
  makeModel: EquipmentModel;
  equipType: EquipmentType;
  jobEquipment: JobEquipment;
  options: EquipmentOption[];
}

export interface EquipmentMachinesLocation {
  job: Job;
  machines: EquipmentMachineFlat[];
}
