import { Key } from '@briebug/ngrx-auto-entity';
import { EquipmentOptionType } from './equipment-option-type.model';
import { EquipmentType } from './equipment-type.model';

export interface JobPlanEquipReqDate {
  date: string;
  startTime: string;
  endTime: string;
}

export interface JobPlanEquipReqDateStatus {
  date: string;
  status: string;
}

export interface JobPlanEquipReqDateVM {
  date: Date;
  startTime: Date;
  endTime: Date;
  status: string;
}

export interface JobPlanEquipReqDateStatusVM {
  date: Date;
  status: string;
}

export class JobPlanEquipReq {
  @Key id?: string;
  equipmentType: number;
  jobPlan: string;
  filled?: boolean;
  notes: string;
  dates: JobPlanEquipReqDate[];
  options: number[];
  canDelete: boolean;
  datesStatus?: JobPlanEquipReqDateStatus[];
}

export interface JobPlanEquipReqVM {
  id?: string;
  equipmentType: EquipmentType;
  jobPlan: string;
  filled?: boolean;
  notes: string;
  dates: JobPlanEquipReqDateVM[];
  options: EquipmentOptionType[];
  canDelete: boolean;
  datesStatus?: JobPlanEquipReqDateStatusVM[];
}
