import { createAction, props } from '@ngrx/store';
import { JobPlannerDateParams } from '@services/api/job-planner.service';

export const loadJobPlanner = createAction(
  '[Job Planner] Load',
  props<{ dateRange: JobPlannerDateParams }>()
);
export const loadJobPlannerSuccess = createAction(
  '[Job Planner API] Load Success',
  props<{ dateRange: JobPlannerDateParams }>()
);
export const loadJobPlannerError = createAction(
  '[Job Planner API] Load Error',
  props<{ error: any }>()
);
