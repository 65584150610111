import { Key } from '@briebug/ngrx-auto-entity';

export class JobLocate {
  @Key id?: string;
  job: string;
  ticketNumber: string;
  ticketType: string;
  ticketTimestamp: string;
  start?: string;
  end?: string;
  renewBy?: string;
}
