import { Key } from '@briebug/ngrx-auto-entity';
import { EquipmentTypeVM } from './equipment-type.model';

export class EquipmentClass {
  @Key id: number;
  name: string;
  delta: number;
}

export interface EquipmentClassVM {
  id: number;
  name: string;
  delta: number;
  equipmentTypes: EquipmentTypeVM[];
}
