import { IEntityState } from '@briebug/ngrx-auto-entity';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import {
  EquipmentClass,
  EquipmentType,
  EquipmentModel,
  EquipmentMachine,
  EquipmentOptionType,
  EquipmentOption,
  EquipmentUntrackedClass,
  EquipmentUntrackedMachine,
  User,
  UserRole,
  ImportStatus,
  JobEquipment,
  MaterialCategory,
  MaterialVendor,
  SubcontractorCategory,
  SubcontractorVendor,
  Vendor,
  RestorationCategory,
  RestorationType,
  RestorationVendor,
  Job,
  JobPlanEquipReq,
  TruckingRequirement,
  TruckingMove,
  TruckingEquipLocation,
  JobPlan,
  JobPlanManager,
  JobPlanManaged,
  JobPlanCrewRequirement,
  JobPlanCrewAssignment,
  Crew,
  JobLocate,
  JobPlanTentative,
} from '@app/models';
import { equipClassReducer } from './equipment-class.state';
import { equipTypeReducer } from './equipment-type.state';
import { equipModelReducer } from './equipment-model.state';
import { equipMachineReducer } from './equipment-machine.state';
import { equipOptTypeReducer } from './equipment-option-type.state';
import { equipOptionReducer } from './equipment-option.state';
import * as EquipState from './equipment/equipment.reducer';
import * as JobPlannerState from './job-planner/job-planner.reducer';
import * as MaterialsState from './materials/materials.reducer';
import * as RestorationState from './restoration/restoration.reducer';
import * as SubcontractorsState from './subcontractors/subcontractors.reducer';
import * as TruckingState from './trucking/trucking.reducer';
import * as UserMe from './user-me/user-me.reducer';
import { equipUntrackedClassReducer } from './equipment-untracked-class.state';
import { equipUntrackedMachineReducer } from './equipment-untracked-machine.state';
import { userReducer } from './user.state';
import { userRoleReducer } from './user-role.state';
import { importStatusReducer } from './import-status.state';
import { materialCategoryReducer } from './material-category.state';
import { materialVendorReducer } from './material-vendor.state';
import { subcontractorCategoryReducer } from './subcontractor-category.state';
import { subcontractorVendorReducer } from './subcontractor-vendor.state';
import { vendorReducer } from './vendor.state';
import { restorationCategoryReducer } from './restoration-category.state';
import { restorationTypeReducer } from './restoration-type.state';
import { restorationVendorReducer } from './restoration-vendor.state';
import { jobEquipmentReducer } from './job-equipment.state';
import { jobReducer } from './job.state';
import { jobPlanEquipReqReducer } from './job-plan-equip-req.state';
import { truckingRequirementReducer } from './trucking-requirement.state';
import { truckingMoveReducer } from './trucking-move.state';
import { truckingEquipLocationReducer } from './trucking-equip-location.state';
import { jobPlanReducer } from './job-plan.state';
import { jobPlanManagerReducer } from './job-plan-manager.state';
import { jobPlanManagedReducer } from './job-plan-managed.state';
import { jobPlanCrewReqReducer } from './job-plan-crew-req.state';
import { jobPlanCrewAssignmentReducer } from './job-plan-crew-assignment.state';
import { crewReducer } from './crew.state';
import { jobLocateReducer } from './job-locate.state';
import { jobPlanTentativeReducer } from './job-plan-tentative.state';

export interface IAppState {
  crew: IEntityState<Crew>;
  equipmentClass: IEntityState<EquipmentClass>;
  equipmentType: IEntityState<EquipmentType>;
  equipmentModel: IEntityState<EquipmentModel>;
  equipmentMachine: IEntityState<EquipmentMachine>;
  equipmentOptionType: IEntityState<EquipmentOptionType>;
  equipmentOption: IEntityState<EquipmentOption>;
  equipmentUntrackedClass: IEntityState<EquipmentUntrackedClass>;
  equipmentUntrackedMachine: IEntityState<EquipmentUntrackedMachine>;
  equipment: EquipState.State;
  importStatus: IEntityState<ImportStatus>;
  job: IEntityState<Job>;
  jobEquipment: IEntityState<JobEquipment>;
  jobLocate: IEntityState<JobLocate>;
  jobPlanner: JobPlannerState.State;
  jobPlan: IEntityState<JobPlan>;
  jobPlanManager: IEntityState<JobPlanManager>;
  jobPlanManaged: IEntityState<JobPlanManaged>;
  jobPlanCrewRequirement: IEntityState<JobPlanCrewRequirement>;
  jobPlanCrewAssignment: IEntityState<JobPlanCrewAssignment>;
  jobPlanEquipReq: IEntityState<JobPlanEquipReq>;
  jobPlanTentative: IEntityState<JobPlanTentative>;
  materials: MaterialsState.State;
  materialCategory: IEntityState<MaterialCategory>;
  materialVendor: IEntityState<MaterialVendor>;
  restoration: RestorationState.State;
  restorationCategory: IEntityState<RestorationCategory>;
  restorationType: IEntityState<RestorationType>;
  restorationVendor: IEntityState<RestorationVendor>;
  subcontractors: SubcontractorsState.State;
  subcontractorCategory: IEntityState<SubcontractorCategory>;
  subcontractorVendor: IEntityState<SubcontractorVendor>;
  trucking: TruckingState.State;
  truckingEquipLocation: IEntityState<TruckingEquipLocation>;
  truckingMove: IEntityState<TruckingMove>;
  truckingRequirement: IEntityState<TruckingRequirement>;
  user: IEntityState<User>;
  userMe: UserMe.State;
  userRole: IEntityState<UserRole>;
  vendor: IEntityState<Vendor>;
}

export type AppState = IAppState;

export const appReducer: ActionReducerMap<AppState> = {
  crew: crewReducer,
  equipmentClass: equipClassReducer,
  equipmentType: equipTypeReducer,
  equipmentModel: equipModelReducer,
  equipmentMachine: equipMachineReducer,
  equipmentOptionType: equipOptTypeReducer,
  equipmentOption: equipOptionReducer,
  equipmentUntrackedClass: equipUntrackedClassReducer,
  equipmentUntrackedMachine: equipUntrackedMachineReducer,
  equipment: EquipState.reducer,
  importStatus: importStatusReducer,
  job: jobReducer,
  jobEquipment: jobEquipmentReducer,
  jobLocate: jobLocateReducer,
  jobPlanner: JobPlannerState.reducer,
  jobPlan: jobPlanReducer,
  jobPlanManager: jobPlanManagerReducer,
  jobPlanManaged: jobPlanManagedReducer,
  jobPlanCrewRequirement: jobPlanCrewReqReducer,
  jobPlanCrewAssignment: jobPlanCrewAssignmentReducer,
  jobPlanEquipReq: jobPlanEquipReqReducer,
  jobPlanTentative: jobPlanTentativeReducer,
  materials: MaterialsState.reducer,
  materialCategory: materialCategoryReducer,
  materialVendor: materialVendorReducer,
  restoration: RestorationState.reducer,
  restorationCategory: restorationCategoryReducer,
  restorationType: restorationTypeReducer,
  restorationVendor: restorationVendorReducer,
  subcontractors: SubcontractorsState.reducer,
  subcontractorCategory: subcontractorCategoryReducer,
  subcontractorVendor: subcontractorVendorReducer,
  trucking: TruckingState.reducer,
  truckingEquipLocation: truckingEquipLocationReducer,
  truckingMove: truckingMoveReducer,
  truckingRequirement: truckingRequirementReducer,
  user: userReducer,
  userMe: UserMe.reducer,
  userRole: userRoleReducer,
  vendor: vendorReducer,
};

export const appMetaReducers: Array<
  MetaReducer<AppState>
> = !environment.production ? [storeFreeze] : [];
