import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { environment } from '@env/environment';
import { EquipmentClassFacade } from './facades/equipment-class.facade';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@services/auth/authentication.service';
import { Store } from '@ngrx/store';
import { AppState } from './state';
import { loadAllEquipment } from './state/equipment/equipment.actions';
import { LoadAll } from '@briebug/ngrx-auto-entity';
import { User, UserRole } from './models';
import { loadUserMe } from './state/user-me/user-me.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  subs: Subscription;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthenticationService,
    private store$: Store<AppState>
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.subs = this.authService.authenticationState.subscribe(authState => {
        if (authState.authenticated) {
          this.store$.dispatch(loadUserMe());
          this.store$.dispatch(loadAllEquipment());
          this.store$.dispatch(new LoadAll(User));
          this.store$.dispatch(new LoadAll(UserRole));
        }
      });
    });
  }
}
