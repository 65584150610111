import { Key } from '@briebug/ngrx-auto-entity';

export class Vendor {
  @Key id: string;
  accountingId: string;
  name: string;
  phone: string;
  fax: string;
  email: string;
  vendorType: string;
}

export class VendorApiPage {
  count: number;
  next: string;
  previous: string;
  results: Vendor[];
}
