import { Key } from '@briebug/ngrx-auto-entity';
import { CrewVM, Crew } from './crew.model';
import { User } from './user.model';

export interface JobPlanCrewAssignmentSub {
  original: number;
  user: string;
}

export class JobPlanCrewAssignment {
  @Key id?: string;
  date: string;
  jobPlan: string;
  jpCrewReq: string;
  crew: string | Crew;
  subs: JobPlanCrewAssignmentSub[];
}

export interface JobPlanCrewAssignmentVM {
  id?: string;
  date: Date;
  jobPlan: string;
  jpCrewReq: string;
  crew: CrewVM;
  subs: { [key: number]: User };
  extraEmployees: User[];
}
